import { useState } from 'react';
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/24/outline';

import { Product } from 'types/products.interface';
import { Rule } from 'types/rules.interface';
import DetailedPrices from 'components/DetailedPrices/DetailedPrices';
import ProductCounter from 'components/common/ProductCounter/ProductCounter';

interface CartItemProps {
  product: Product,
  productRules: Rule,
  goodsCount: number,
  total: number,
  setProductNumber: (value: string) => void
}

const CartItem = ({
  product,
  productRules,
  goodsCount,
  total,
  setProductNumber
}: CartItemProps) => {
  const [open, setOpen] = useState(false);
  const onOpen = () => setOpen((isOpen) => !isOpen);
  const collapseClassname = open ? 'collapse-open' : '';

  return (
    <div className={`flex flex-col w-full ${collapseClassname}`}>
      <div className="flex w-full gap-4 justify-between items-center py-2 px-4 border-base-300 bg-base-100 rounded-lg">
        <div className="flex flex-1 items-center">
          <h4 className="m-0 text-left">{product.name}</h4>
          <div className="text-left ml-2">
            <ProductCounter value={`${goodsCount}`} setValue={setProductNumber} />
          </div>
        </div>
        <div className="text-success font-bold">{total}€</div>
        <button className="btn btn-xs min-h-0 p-1 h-auto" onClick={onOpen}>
          {open ? <ChevronDownIcon className="w-3 h-3" /> : <ChevronRightIcon className="w-3 h-3" />}
        </button>
      </div>
      <div className="collapse-content flex flex-col w-full gap-4 pt-3">
        <DetailedPrices productRules={productRules} goodsCount={goodsCount} />
      </div>
    </div>
  );
};

export default CartItem;
