import { useReducer } from 'react';

import ProductsList from 'components/ProductsList/ProductsList';
import CartPanel from 'components/CartPanel/CartPanel';
import Modal, { initialStateProps } from 'components/Modal/Modal';
import Header from 'components/Header/Header';
import checkout from 'utils/checkout';

import { onAddToCartProps } from 'types/cart.interface';
import { idType } from 'types/products.interface';

import reducer, {
  ADD_PRODUCT, ADD_TO_CART, CLOSE_MODAL, EDIT_GOODS, initialState, OPEN_MODAL, TOGGLE_CART
} from './reducer/reducer';
import CloseButton from 'components/common/CloseButton/CloseButton';

const panelAnimations = 'transition-all ease-in-out duration-300';

const Home = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { goods, openCart, products, rules, id, modalOpen } = state;

  const count = goods.length;
  const productsListWidth = openCart ? 'md:w-1/2 lg:w-2/3' : 'w-full';
  const cartPanelWidth = openCart ? 'md:w-1/2 lg:w-1/3' : 'w-0';
  const { price } = checkout(goods, rules);

  /** Reducer actions */
  const onToggleCart = () => dispatch({ type: TOGGLE_CART });
  const onAddToCart = ({ count = 0, id = null }: onAddToCartProps) => {
    dispatch({ type: ADD_TO_CART, payload: { count, id } });
  };
  const onEditProduct = (id: idType) => dispatch({ type: OPEN_MODAL, id });
  const onAddProduct = (id: idType, state: initialStateProps) => {
    dispatch({ type: ADD_PRODUCT, id, product: state });
  };
  const editGoods = (newGoods: string) => dispatch({ type: EDIT_GOODS , goods: newGoods });
  const closeModal = () => dispatch({ type: CLOSE_MODAL });
  const openModal = (id: idType) => dispatch({ type: OPEN_MODAL, id });

  return (
    <div className="w-full flex">
      <div className={`flex flex-col px-20 ${panelAnimations} ${productsListWidth}`}>
        <Header
          price={price}
          count={count}
          onToggleCart={onToggleCart}
        />
        <div className="flex w-full flex-col">
          <ProductsList
            products={products}
            rules={rules}
            onAddToCart={onAddToCart}
            onEditProduct={onEditProduct}
            onNewProduct={() => openModal(null)}
          />
        </div>
      </div>
      <div className={`flex bg-neutral overflow-hidden md:relative absolute z-10 inset-0 w-full ${panelAnimations} ${cartPanelWidth}`}>
        <div className="flex w-full justify-center py-8">
          <div className="prose text-center w-full relative">
            <CloseButton onClick={onToggleCart} />
            <h2>CartPanel</h2>
            <CartPanel
              goods={goods}
              products={products}
              rules={rules}
              setGoods={editGoods}
            />
          </div>
        </div>
      </div>
      <Modal
        id={id}
        open={modalOpen}
        closeModal={closeModal}
        onAddProduct={onAddProduct}
        products={products}
        rules={rules}
      />
    </div>
  );
}

export default Home;
