import { Products } from 'types/products.interface';

/** Returns the next id  */
const getNextId = (products: Products) => {
  const productsKeys = Object.keys(products);
  const lastKey = productsKeys[productsKeys.length - 1];

  return String.fromCharCode(lastKey.charCodeAt(0) + 1);
};

export default getNextId;
