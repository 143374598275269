import { Rules } from 'types/rules.interface';

import getGoodsByKey from './getGoodsByKey';

interface ReduceProps {
  price: number,
  prices: { [n: string]: number }
}

/** Chekout function, calculates the total price and returns the prices by goods id */
const checkout = (goods: string, rules: Rules): ReduceProps => {
  const goodsCounts = getGoodsByKey(goods);

  return Object.keys(goodsCounts).reduce(({ price, prices }: ReduceProps, key: string) => {
    const productNumber = goodsCounts[key];
    const { unitPrice, discount } = rules[key];
    let addedPricesObj;
    let addedUnitPrice;

    if (!discount) {
      const delta = productNumber * unitPrice;
      addedUnitPrice = price + delta;
      addedPricesObj = (prices[key] || 0) + delta;
      return {
        price: addedUnitPrice,
        prices: { ...prices, [key]: addedPricesObj }
      };
    }

    const { count, specialPrice } = discount;
    const discountsNumber = Math.floor(productNumber / count);
    const leftNumber = productNumber % count;
    const discDelta = (discountsNumber * specialPrice) + (leftNumber * unitPrice);

    addedPricesObj = (prices[key] || 0) + discDelta;
    addedUnitPrice = price + discDelta;

    return {
      price: addedUnitPrice,
      prices: { ...prices, [key]: addedPricesObj }
    };
  }, { price: 0, prices: {} });
};

export default checkout;
