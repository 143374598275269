import { PlusCircleIcon } from '@heroicons/react/24/outline';

interface NewProductProps {
  onClick: (e: React.MouseEvent) => void
}

const classNames = [
  'flex items-center justify-center min-h-[7rem]',
  'border border-dashed border-base-100 bg-neutral rounded-lg text-base-100 cursor-pointer',
  'hover:border-success hover:text-success'
];

const NewProduct = ({ onClick }: NewProductProps) => {
  return (
    <div
      onClick={onClick}
      className={classNames.join(' ')}
    >
      <PlusCircleIcon className="w-10 h-10" />
    </div>
  );
};

export default NewProduct;
