import { Rule } from 'types/rules.interface';

interface DetailedPricesProps {
    productRules: Rule,
    goodsCount: number
}

const colStyle = 'flex justify-between text-sm';

const DetailedPrices = ({ productRules, goodsCount }: DetailedPricesProps) => {
    const { discount: { count = 0, specialPrice = 0 } = {}, unitPrice } = productRules;

    const discountsNumber = count > 0 ? Math.floor(goodsCount / count) : 0;
    const leftNumber = count > 0 ? goodsCount % count : 0;
    const normalPrice = goodsCount * unitPrice;

    const discountAmount = discountsNumber * specialPrice;
    const leftAmount = leftNumber * unitPrice;

    if (count > 0) {
        return (
            <>
                {
                    discountAmount > 0 &&
                        <div className={colStyle}>
                            <div>Discount - {count} for {specialPrice}€ | {discountsNumber * count} ({discountsNumber} x {count})</div>
                            <div>{discountAmount}€</div>
                        </div>
                }
                {
                    leftAmount > 0 &&
                        <div className={colStyle}>
                            <div>Normal price | {leftNumber}</div>
                            <div>{leftAmount}€</div>
                        </div>
                }
            </>
        );
    }

    return (
        <div className={colStyle}>
            <div>Normal price | {goodsCount}</div>
            <div>{normalPrice}€</div>
        </div>
    );
};

export default DetailedPrices;
