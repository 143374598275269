import { useEffect, useState } from 'react';
import { idType, Products } from 'types/products.interface';
import { Rules } from 'types/rules.interface';
import ProductCounter from 'components/common/ProductCounter/ProductCounter';
import NumberInput from 'components/NumberInput/NumberInput';
import ProductIcon from 'components/common/ProductIcon/ProductIcon';

import getModalErrors from './getModalErrors';
import getParsedState from './getParsedState';
import CloseButton from '../common/CloseButton/CloseButton';

export interface initialStateProps {
  name: string,
  price: string,
  discount: boolean,
  discountPrice: string,
  discountCount: string,
  errors: string[],
  image: string | null
}
interface ModalProps {
  id: idType,
  open: boolean,
  closeModal: (e: React.MouseEvent) => void,
  onAddProduct: (id: idType, state: initialStateProps) => void,
  products: Products,
  rules: Rules
}

const initialState = {
  name: '',
  price: '0',
  discount: false,
  discountPrice: '0',
  discountCount: '0',
  errors: [],
  image: null
};

const Modal = ({
  id,
  open,
  onAddProduct,
  closeModal,
  products,
  rules
}: ModalProps) => {
  const [state, setState] = useState<initialStateProps>(initialState);
  const {
    name, image, discount, discountPrice, discountCount, errors, price
  } = state;
  const modalStyle = open ? 'modal-open' : '';

  const onChange = (type: string, value: string) => {
    switch (type) {
      case 'discount':
        setState({ ...state, discount: !discount, errors: [] });
        break;
      default:
        setState({ ...state, [type]: value, errors: [] });
        break;
    }
  };

  const onDiscountPriceChange = (value: string) => onChange('discountPrice', value);
  const onDiscountCountChange = (value: string) => discountCount !== value ? onChange('discountCount', value) : null;
  const onAddClick = () => {
    if (!discount && name !== '' && price !== '0') {
      onAddProduct(id, state);
      return;
    }
    const errorsAcc = getModalErrors(state);

    if (errorsAcc.length) {
      setState({ ...state, errors: errorsAcc });
      return;
    }

    onAddProduct(id, state);
  };

  const onSetData = (newState: initialStateProps) => {
    setState(newState);
  };

  const handleChange = (event: React.ChangeEvent) => {
    const target = event.target as HTMLInputElement;
    const name = target.files && target.files[0] ? target.files[0].name : null;
    if (name) {
      setState({ ...state, image: `/images/fruits/${name}` })
    }
  };

  useEffect(() => {
    const newState = getParsedState(id, products, rules);
    onSetData(newState);
  }, [id, products, rules]);

  return (
    <>
      <div className={`modal ${modalStyle}`}>
        <div className="modal-box relative bg-neutral">
          <CloseButton onClick={closeModal} />
          <div className="w-full flex gap-2">
            <label className="flex cursor-pointer">
              <ProductIcon image={image} name={name} />
              <input type="file" className="w-full hidden" onChange={handleChange} />
            </label>
            <h3 className="text-lg font-bold">{id ? 'Edit' : 'Add'} {name}</h3>
          </div>
          <div className="flex w-full mt-4 gap-8">
            <div className="form-control w-full">
              <input
                type="text"
                placeholder="Name"
                className="input input-bordered w-full"
                value={name}
                onChange={(e) => onChange('name', e.target.value)}
              />
            </div>
            <div className="flex w-full justify-end gap-2">
              <div className="flex items-center">
                Price :
              </div>
              <NumberInput value={price} setValue={(newValue: string) => onChange('price', newValue)} />
              <div className="flex items-center">
                €
              </div>
            </div>
          </div>
          <div className="flex w-full mt-4 gap-2 h-10">
            <div className="form-control">
              <label className="label cursor-pointer flex justify-end gap-4">
                <span className="label-text">Discount</span>
                <input
                  type="checkbox"
                  className="toggle toggle-info"
                  onChange={(e) => onChange('discount', e.target.value)}
                  checked={discount}
                />
              </label>
            </div>
            {
              discount &&
                <>
                  <ProductCounter
                    onChange={onDiscountCountChange}
                    value={discountCount}
                    setValue={(newValue: string) => onChange('discountCount', newValue)}
                  />
                  <div className="flex items-center">for</div>
                  <div className="flex">
                    <NumberInput value={discountPrice} setValue={onDiscountPriceChange} />
                  </div>
                  <div className="flex items-center">€</div>
                </>
            }
          </div>
          {
            errors.length > 0 &&
              <div className="text-sm text-error px-1 pt-2">
                {errors.join(' | ')}
              </div>
          }
          <div className="modal-action">
            <button className="btn bg-base-100" onClick={closeModal}>CANCEL</button>
            <button className="btn btn-secondary" onClick={onAddClick}>CONFIRM</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Modal;
