import { useEffect } from 'react';
import { ShoppingCartIcon } from '@heroicons/react/24/outline';
import NumberInput from 'components/NumberInput/NumberInput';

const buttonStyle = 'btn btn-primary h-auto cursor-pointer outline-none min-h-0';

interface ProductCounterProps {
    onAddToCart?: (count: number) => void,
    onChange?: (value: string) => void,
    value: string,
    setValue: (value: string) => void
}

const incrDecrValue = (increment: boolean, value: string) => {
    if (!increment && value === '0') {
        return value;
    }
    const parsedOldValue = parseInt(value);
    return increment ? `${parsedOldValue + 1}` : `${parsedOldValue - 1}`;
};

const ProductCounter = ({ onAddToCart, onChange, setValue, value = '0' }: ProductCounterProps) => {
    const onClick = (increment: boolean) => {
        const newValue = incrDecrValue(increment, value);
        setValue(newValue);
    };

    const onAddToCartClick = () => {
        onAddToCart && onAddToCart(parseInt(value));
        setValue('0');
    };

    useEffect(() => {
        onChange && onChange(value);
    }, [value, onChange]);

    return (
        <div className="flex flex-1 gap-2 justify-end">
            <button
                className={`${buttonStyle}`}
                onClick={() => onClick(false)}
            >
                <span className="mx-auto">-</span>
            </button>
            <NumberInput value={value} setValue={setValue} />
            <button
                className={`${buttonStyle}`}
                onClick={() => onClick(true)}
            >
                <span className="m-auto">+</span>
            </button>
            {
                onAddToCart &&
                    <button
                            className="btn btn-secondary h-auto min-h-0"
                            onClick={onAddToCartClick}
                        >
                            <ShoppingCartIcon className="h-4 w-4"/>
                    </button>
                }
        </div>
    );
};

export default ProductCounter;
