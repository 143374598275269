import { Products } from 'types/products.interface';
import { Rules } from 'types/rules.interface';

import { initialStateProps } from './Modal';

const getParsedState = (id: string | null, products: Products, rules: Rules): initialStateProps => {
  const {
    unitPrice = '0',
    discount: { specialPrice = '0', count = '0' } = {},
    discount: discountObj = {}
  } = id ? rules[id] : {};

  const { name: productName = '', image = null } = id ? products[id] : {};

  return {
    name: productName,
    price: unitPrice.toString(),
    discount: Object.values(discountObj).length > 0,
    discountPrice: specialPrice.toString(),
    discountCount: count.toString(),
    errors: [],
    image
  };
};

export default getParsedState;
