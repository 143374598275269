import { initialStateProps } from './Modal';

const getModalErrors = (state: initialStateProps) => {
    const { name, price, discountPrice, discountCount, discount } = state;
    const errorsAcc = [];

    if (name === '') {
        errorsAcc.push('Please enter a product name');
    }

    if (price === '0') {
        errorsAcc.push('The product price cannot be zero');
    }

    if (discount && discountCount === '0') {
        errorsAcc.push('Discount count must be defined');
    }

    if (discount && discountPrice === '0') {
        errorsAcc.push('Discount price must be defined');
    }

    return errorsAcc;
};

export default getModalErrors;
