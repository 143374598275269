import React from 'react'

interface CloseButtonProps {
  onClick: (e: React.MouseEvent) => void
}

const CloseButton = ({ onClick }: CloseButtonProps) => {
  return (
    <label
      className="btn btn-sm btn-circle absolute right-2 top-2"
      onClick={onClick}
    >
      ✕
    </label>
)
}

export default CloseButton;
