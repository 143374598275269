import { initialStateProps } from 'components/Modal/Modal';

import { idType, Products } from 'types/products.interface';
import { Rules } from 'types/rules.interface';
import { onAddToCartProps } from 'types/cart.interface';

import onAddProduct from './onAddProduct';
import onAddToCart from './onAddToCart';

export const ADD_TO_CART = 'cart/add';
export const ADD_PRODUCT = 'product/add';
export const CLOSE_MODAL = 'modal/close';
export const EDIT_GOODS = 'goods/edit';
export const OPEN_MODAL = 'modal/open';
export const TOGGLE_CART = 'cart/toggle';

export interface ReducerProps {
  goods: string,
  openCart: boolean,
  products: Products,
  rules: Rules,
  modalOpen: boolean,
  id: idType
}

const initialProducts: Products = {
  'A': { name: 'Apple', image: '/images/fruits/apple.png' },
  'B': { name: 'Kiwi', image: '/images/fruits/kiwi.png' },
  'C': { name: 'Orange', image: '/images/fruits/orange.png' },
  'D': { name: 'Banana', image: null }
};

const initialRules: Rules = {
  'A': { unitPrice: 50, discount: { count: 3, specialPrice: 130 } },
  'B': { unitPrice: 30, discount: { count: 2, specialPrice: 45 } },
  'C': { unitPrice: 20 },
  'D': { unitPrice: 15 }
};

export const initialState: ReducerProps = {
  goods: '',
  openCart: false,
  products: initialProducts,
  rules: initialRules,
  modalOpen: false,
  id: null
};

interface ActionProps {
  type: string,
  payload?: onAddToCartProps,
  product?: initialStateProps,
  goods?: string,
  id?: idType
}

const reducer = (state: ReducerProps = initialState, action: ActionProps): ReducerProps => {
  const { goods, openCart } = state;
  const { type, payload, product, goods: newGoods, id } = action;
  switch (type) {
    case ADD_TO_CART:
      if (!payload) { return state; }
      return { ...state, goods: onAddToCart({ goods, payload }) };
    case ADD_PRODUCT:
      if (!product || id === undefined) { return state; }
      return onAddProduct({ state, product: { id, ...product } });
    case EDIT_GOODS:
      if (newGoods === undefined) { return state; }
      return { ...state, goods: newGoods };
    case TOGGLE_CART:
      return { ...state, openCart: !openCart };
    case OPEN_MODAL:
      if (id === undefined) { return state; }
      return { ...state, modalOpen: true, id };
    case CLOSE_MODAL:
      return { ...state, modalOpen: false };
    default:
      return state;
  }
};

export default reducer;
