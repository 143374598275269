import { useState } from 'react';
import { PencilSquareIcon } from '@heroicons/react/24/outline';

import ProductCounter from 'components/common/ProductCounter/ProductCounter';

import { Product } from 'types/products.interface';
import { Rule } from 'types/rules.interface';

interface ProductItemProps {
    product: Product,
    prices: Rule,
    onAddToCart: (count: number) => void,
    onEditProduct: (e: React.MouseEvent) => void
}

const ProductItem = ({
    product,
    prices,
    onAddToCart,
    onEditProduct
}: ProductItemProps) => {
    const [value, setValue] = useState('0');

    const { name: productName, image } = product;
    const { unitPrice, discount } = prices;
    const { count, specialPrice } = discount || {};

    return (
        <div className="flex flex-col w-full bg-neutral shadow-xl rounded-lg p-4 justify-between relative">
            {
                discount &&
                <div className="absolute -top-2 right-3">
                    <div className="w-full flex justify-center">
                        <div className="badge badge-success">
                            <strong>{count} for {specialPrice}€ !</strong>
                        </div>
                    </div>
                </div>
            }
            <div className="flex w-full gap-2">
                
                    {
                        image &&
                            <div className="w-6 flex items-center">
                                <figure>
                                    <img src={image} alt={productName} />
                                </figure>
                            </div>

                    }
                <div className="flex-1 flex items-center font-bold">
                    {productName}
                </div>
                <div className="flex items-center text-success font-bold">
                    {unitPrice}€
                </div>
            </div>
            <div className="flex w-full rounded-lg relative mt-4 h-8">
                <button
                    className="btn btn-warning min-h-0 h-auto mr-8"
                    onClick={onEditProduct}
                >
                    <PencilSquareIcon className="w-4 h-4" />
                </button>
                <ProductCounter
                    value={value}
                    setValue={setValue}
                    onAddToCart={(count: number) => onAddToCart(count)}
                />
            </div>
        </div>
    );
};

export default ProductItem;