import CartIcon from 'components/CartIcon/CartIcon';

interface HeaderProps {
  price: number,
  count: number,
  onToggleCart: (e: React.MouseEvent) => void
}

const Header = ({ price, count, onToggleCart }: HeaderProps) => {
  return (
    <header className="flex justify-between items-center mt-12 mb-12">
      <div className="flex gap-4 items-center">
        <img className="w-20 h-20" src="/images/logo.png" alt="Fruit Shop Logo" />
        <div className="prose">
          <h2 className="mb-0">WELCOME TO</h2>
          <h1 className="text-warning">THE FRUITS SHOP</h1>
        </div>
      </div>
      <div className="flex gap-4">
        {
          price > 0 &&
          <div className="flex font-bold pt-1">
            {price}€
          </div>
        }
        <CartIcon count={count} onClick={onToggleCart} />
      </div>
    </header>
  );
};

export default Header;
