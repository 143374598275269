import NewProduct from 'components/NewProduct/NewProduct';
import ProductItem from 'components/ProductItem/ProductItem';

import { Products } from 'types/products.interface';
import { Rules } from 'types/rules.interface';
import { onAddToCartProps } from 'types/cart.interface';

interface ProductsListProps {
  products: Products,
  rules: Rules,
  onAddToCart: ({ count, id }: onAddToCartProps) => void,
  onEditProduct: (id: string) => void,
  onNewProduct: (e: React.MouseEvent) => void
}

const ProductsList = ({
  products,
  rules,
  onAddToCart,
  onEditProduct,
  onNewProduct
}: ProductsListProps) => {
  const productsList = Object.keys(products).map((id) => {
    const product = products[id];
    const prices = rules[id];
    const onEdit = () => onEditProduct(id);
    const onAdd = (count: number) => onAddToCart({ count, id })

    return (
      <ProductItem
        key={id}
        product={product}
        prices={prices}
        onAddToCart={onAdd}
        onEditProduct={onEdit}
      />
    );
  });

  return (
    <div className="w-full grid grid-cols-fill-80 gap-4">
      {productsList}
      <NewProduct onClick={onNewProduct} />
    </div>
  )
};

export default ProductsList;
