import { onAddToCartProps } from 'types/cart.interface';

interface OnAddToCartParams {
  goods: string,
  payload: onAddToCartProps
}

/** Called when a set of products is added to the cart */
const onAddToCart = ({ goods, payload }: OnAddToCartParams) => {
  const { count = 0, id } = payload;
  let newString = '';
  for (let i = 0; i < count; i = i + 1) {
      newString += id;
  }
  return `${goods}${newString}`;
};

export default onAddToCart;
