interface NumberInputProps {
    value: string,
    setValue: (value: string) => void
}

const inputStyle = [
    'flex items-center px-2 max-w-[5rem] h-auto w-full',
    'input outline-none focus:outline-none text-center font-semibold'
].join(' ');

const NumberInput = ({ value, setValue }: NumberInputProps) => {
    /**
     * Number input onChange - We use it like a text to avoid leading 0
     * See: https://github.com/facebook/react/issues/9402
     * @param e OnClick event
     */
    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value === '') {
            setValue('');
            return;
        }
        const intValue = parseInt(e.target.value);
        if (intValue >= 0) {
            setValue(`${intValue}`);
            return;
        }
        setValue('0');
    };

    /**
     * Number input onChange - used to always have 0 into our input
     */
    const onBlur = () => {
        if (value === '') {
            setValue('0');
        }
    };

    return (
        <input
            type="number"
            className={inputStyle}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
        />
    );
};

export default NumberInput;
