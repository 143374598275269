import getGoodsByKey from 'utils/getGoodsByKey';
import changeGoodsByKey from 'utils/changeGoodsByKey';
import checkout from 'utils/checkout';
import CartItem from 'components/CartItem/CartItem';

import { Products } from 'types/products.interface';
import { Rules } from 'types/rules.interface';

interface CartPanelProps {
  goods: string,
  products: Products,
  rules: Rules,
  setGoods: (goods: string) => void
}

const CartPanel = ({ goods, products, rules, setGoods }: CartPanelProps) => {
  const count = goods.length;
  const goodsByKey = getGoodsByKey(goods);

  const { price, prices } = checkout(goods, rules);

  if (count === 0) {
    return <span>Your cart is empty</span>;
  }

  const productsList = Object.keys(goodsByKey).sort().map((id: string) => {
    const product  = products[id];
    const productRules  = rules[id];
    const goodsCount = goodsByKey[id];
    const total = prices[id];
    const setProductNumber = (value: string) => setGoods(changeGoodsByKey(goods, id, parseInt(value)));

    return (
      <CartItem
        key={id}
        product={product}
        productRules={productRules}
        goodsCount={goodsCount}
        total={total}
        setProductNumber={setProductNumber}
      />
    );
  });

  return (
    <div className="flex flex-col w-full p-4">
      {productsList}
      <button className="btn btn-secondary flex justify-between">
        <span>CHECKOUT</span><span>TOTAL : {price}€</span>
      </button>
    </div>
  )
};

export default CartPanel;
