import EmptyImage from 'components/common/EmptyImage/EmptyImage';
import { Product } from 'types/products.interface';

const ProductIcon = ({ image, name }: Product) => {
  if (image) {
    return (
      <img
        className="h-6 w-6 object-cover rounded-full"
        src={image}
        alt={name}
      />
    );
  }

  return <EmptyImage />;
};

export default ProductIcon;
