import { initialStateProps } from 'components/Modal/Modal';
import getNextId from 'utils/getNextId';

import { idType } from 'types/products.interface';

import { ReducerProps } from './reducer';

interface OnAddProductProps {
  state: ReducerProps,
  product: initialStateProps & { id: idType }
}

/** Called when a product is edited or a new product is added (id === null) */
const onAddProduct = ({ state, product }: OnAddProductProps) => {
  const { id, name, image, price, discount, discountPrice, discountCount } = product;
  const { products, rules } = state;

  const newId = id || getNextId(products);
  const newProducts = { ...products, [newId]: { ...products[newId], name, image } };

  const discountObj = discount ? {
    discount: {
      count: parseInt(discountCount),
      specialPrice: parseInt(discountPrice)
    }
  } : {};

  const newRule = {
    unitPrice: parseInt(price),
    ...discountObj
  };

  const newRules = { ...rules, [newId]: newRule };

  return {
    ...state,
    products: newProducts,
    rules: newRules,
    modalOpen: false
  };
};

export default onAddProduct;
