// import React from 'react';
import { ShoppingCartIcon } from '@heroicons/react/24/outline';
import { ShoppingCartIcon as SolidShoppingCartIcon } from '@heroicons/react/24/solid';

interface CartIconProps {
    count: number,
    onClick: (e: React.MouseEvent) => void
}

const CartIcon = ({ count, onClick }: CartIconProps) => {
    const isActive = count > 0;

    /* ! swap-off > visible - swap-on invisible */
    const emptySwapStyle = !isActive ? 'swap-off' : 'swap-on';
    const activeSwapStyle = isActive ? 'swap-off' : 'swap-on';
    const indicatorStyle = isActive ? 'badge-secondary font-bold' : '';

    return (
        <div className="relative cursor-pointer" onClick={onClick}>
            <div className="indicator">
                <span className={`indicator-item badge text-xs h-auto py-1 px-2 ${indicatorStyle}`}>{count}</span> 
                <div className="place-items-center">
                    <label className="swap">
                        <div className={emptySwapStyle}>
                            <ShoppingCartIcon className="w-8 h-8" />
                        </div>
                        <div className={activeSwapStyle}>
                            <SolidShoppingCartIcon className="w-8 h-8 text-white" />
                        </div>
                    </label>
                </div>
            </div>
        </div>
    );
};

export default CartIcon;
